import React from "react"
import styled, { css } from "styled-components"
import Img from "gatsby-image"
import get from "lodash.get"
import { Grid, Box } from "../components/style"

export function Image({ data, html }) {
  const centered = data.center ? data.center : false
  return (
    data.image &&
    data.image.childImageSharp && (
      <Grid>
        <Box>
          <ImageWrapper>
            <Img fluid={data.image.childImageSharp.fluid} />
          </ImageWrapper>
        </Box>
        {data.content && (
          <Box>
            <StyledContent
              center={centered}
              dangerouslySetInnerHTML={{
                __html: html,
              }}
            ></StyledContent>
          </Box>
        )}
      </Grid>
    )
  )
}

const ImageWrapper = styled.div`
  overflow: hidden;
`

const StyledContent = styled.div`
  ${props =>
    props.center &&
    css`
      text-align: center;
    `};
  p {
    font-size: 0.85em;
    line-height: 1.5;
  }
  h2, h3, h4, h5 {
    font-weight: bold;
    margin-bottom: 0.5em;
  }
`

export const ImageBlock = {
  label: "Image",
  name: "image",
  key: "test",
  defaultItem: {
    image: "",
  },
  fields: [
    {
      label: "Image",
      name: "image",
      component: "image",
      parse: filename => `../images/${filename}`,
      uploadDir: () => `/content/images/`,
      previewSrc: (formValues, fieldProps) => {
        const pathName = fieldProps.input.name.replace("rawJson", "jsonNode")
        const imageNode = get(formValues, pathName)
        if (!imageNode || !imageNode.childImageSharp) return ""
        return imageNode.childImageSharp.fluid.src
      },
    },
    {
      label: "Caption",
      name: "content", 
      component: "markdown",
    },
  ],
}
