import React from "react"
import styled, { css } from "styled-components"
import { Grid, Box } from "../components/style"

export function Video({ data, html }) {
  if (!data.video) return (<>Nothing</>)
  const centered = data.center ? data.center : false

  const youtubePrefix = "https://www.youtube.com/watch?v="
  const id = data.video.slice(youtubePrefix.length)

  const url = `https://www.youtube.com/embed/${id}`
  return (
      <BlockWrapper>
        {data.title && (<h3>{data.title}</h3>)}
        <div>
          <VideoWrapper>
            <iframe src={url} frameborder='0' allowfullscreen></iframe>
          </VideoWrapper>
        </div>
        {data.content && (
          <div>
            <StyledContent
              center={centered}
              dangerouslySetInnerHTML={{
                __html: html,
              }}
            ></StyledContent>
          </div>
        )}
      </BlockWrapper>
  )
}

const BlockWrapper = styled.div`
  margin: 2.2em 0;
  h2, h3, h4, h5 {
    font-weight: bold;
    margin-bottom: 0.5em;
  }
`

const VideoWrapper = styled.div`
  position: relative; 
  padding-bottom: 56.25%; 
  height: 0; 
  overflow: hidden; 
  max-width: 100%;

  iframe, object, embed {
    position: absolute; 
    top: 0; 
    left: 0; 
    width: 100%;
    height: 100%; 
  }
`

const StyledContent = styled.div`
  ${props =>
    props.center &&
    css`
      text-align: center;
    `};
  p {
    font-size: 0.85em;
    margin-top: 1em;
    line-height: 1.5;
  }
  h2, h3, h4, h5 {
    font-weight: bold;
    margin-bottom: 0.5em;
  }
`

export const VideoBlock = {
  label: "Video",
  name: "video",
  defaultItem: {
    video: "",
    caption: "",
  },
  fields: [
    {
      label: "Title",
      name: "title",
      component: "text",
    },
    {
      label: "Video",
      name: "video",
      component: "text",
    },
    {
      label: "Caption",
      name: "content", 
      component: "markdown",
    },
  ],
}
